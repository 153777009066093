import { OutgoingMethodology } from '../constants'

export const getInitialBudgetByMethodology = (
  methodology: OutgoingMethodology,
) => {
  switch (methodology) {
    case OutgoingMethodology.INITIAL_BUDGET: {
      return 'outgoingsSettings.data[0].initialBudget'
    }
    case OutgoingMethodology.DOLLAR_PER_SQM: {
      return 'outgoingsSettings.data[0].dollarPerSqm.initialBudget'
    }
    case OutgoingMethodology.PERCENTAGE: {
      return 'outgoingsSettings.data[0].percentage.initialBudget'
    }
  }
}
