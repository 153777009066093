import { OutgoingIncreaseType } from '../constants'

export const getBudgetIncreaseByIncreaseType = (
  increaseType: OutgoingIncreaseType,
) => {
  switch (increaseType) {
    case OutgoingIncreaseType.FIXED_AMOUNT: {
      return 'outgoingsIncrease.data[0].fixedAmount'
    }
    case OutgoingIncreaseType.FIXED_PERCENTAGE: {
      return 'outgoingsIncrease.data[0].fixedPercentage'
    }
    // case OutgoingIncreaseType.CPI: {
    //   return 'outgoingsIncrease.data[0].cpi'
    // }
    // case OutgoingIncreaseType.CPI_PLUS: {
    //   return 'outgoingsIncrease.data[0].cpiPercent'
    // }
  }
}
