import type { MaybeRef } from 'vue'
import dayjs, { type Dayjs } from 'dayjs'
import {
  useDatesGenerator,
  YearType,
  useTableData,
  type INode,
  type INodeField,
  joinPath,
} from '@manager'
import type { OmitKeysStartingWith } from '~/types/helpers'
import { OutgoingIncreaseType, OutgoingYearType } from '../constants'
import type { Outgoing, OutgoingForm } from '../types'
import {
  getBudgetIncreaseByIncreaseType,
  getInitialBudgetByMethodology,
} from '../utils'

export type YearModel = {
  year: number
  startDate: string
  endDate: string
  dates: string
  status: 'past' | 'present' | 'future'
  calculated_dates: number
}

type OutgoingWithYear = OmitKeysStartingWith<Outgoing, 'calculated_'> &
  YearModel

export function generateOutgoingsTableData({
  initialValue,
  objectPath,
  nodes,
}: {
  initialValue: MaybeRef<Outgoing[]>
  objectPath: string
  nodes: INode[]
}) {
  const { formValue, getProperty } = useManagerFormValue<OutgoingForm>()
  const settings = computed(
    () => formValue?.value?.outgoingsSettings?.data?.[0],
  )
  const increase = computed(
    () => formValue?.value?.outgoingsIncrease?.data?.[0],
  )
  const annualIncrease = computed(
    () => increase.value?.setAnnualIncrease ?? false,
  )
  const joinDataPath = (index: number) => joinPath(objectPath, `data[${index}]`)

  const budgetNode = computed(
    () => nodes.find((node) => node.name === 'budget') as INodeField,
  )
  const varianceNode = computed(
    () => nodes.find((node) => node.name === 'variance') as INodeField,
  )

  const initializeBudgetFormula = (index: number) => {
    if (
      !annualIncrease.value ||
      !settings.value?.outgoingMethodology ||
      !increase.value?.increaseType ||
      index === 0
    ) {
      return joinPath(joinPath(objectPath, `data[]`), 'budget')
    }

    const previousBudget = joinPath(joinDataPath(index - 1), 'budget')

    const increaseValue = getBudgetIncreaseByIncreaseType(
      increase.value.increaseType,
    )

    switch (increase.value.increaseType) {
      case OutgoingIncreaseType.FIXED_AMOUNT:
        return `${previousBudget} + ${increaseValue}`
      case OutgoingIncreaseType.FIXED_PERCENTAGE:
        return `${previousBudget} + (${previousBudget} * ${increaseValue})`
    }
  }

  const { data, filteredData, addOrUpdate, remove } =
    useTableData<OutgoingWithYear>(
      initialValue as unknown as MaybeRef<OutgoingWithYear[]>,
      {
        path: objectPath,
        initialItem: computed(() => {
          let budget: number | undefined
          if (settings.value) {
            budget = getProperty(
              getInitialBudgetByMethodology(settings.value.outgoingMethodology),
            ) as number
          }

          return {
            year: undefined,
            budget,
            actual: undefined,
            variance: undefined,
            payableDate: undefined,
          }
        }),
        initialItemCondition: ({ index }) => index === 0,
        watchInitialItem: true,
        evaluate: (_, index) => {
          return {
            budget: computed(() => initializeBudgetFormula(index)),
            variance: computed(() => varianceNode.value?.formula),
          }
        },
        onAdd: (item) => {
          Object.defineProperties(item, {
            startDate: {
              enumerable: false,
              writable: true,
              configurable: true,
            },
            endDate: {
              enumerable: false,
              writable: true,
              configurable: true,
            },
            status: {
              enumerable: false,
              writable: true,
              configurable: true,
            },
            calculated_dates: {
              enumerable: false,
              writable: true,
              configurable: true,
            },
          })
        },
      },
    )

  const leaseStartDate = computed(() => settings.value?.leaseStartDate)
  const leaseEndDate = computed(() => settings.value?.leaseEndDate)
  const financialYearDate = computed(() => {
    switch (settings.value?.outgoingYearType) {
      case OutgoingYearType.FINANCIAL_YEAR_END:
        return settings.value?.financialYearEnd
      case OutgoingYearType.OTHER_YEAR_END:
        return settings.value?.otherYearEnd
    }
  })

  const { generate } = useDatesGenerator(
    {
      startDate: leaseStartDate,
      endDate: leaseEndDate,
      financialYearDate: financialYearDate,
    },
    {
      onAdd: (index, { startDate, endDate }) => {
        const year = index + 1
        addOrUpdate(index, {
          year: year,
          dates: startDate.toISOString(),
          startDate: startDate.format('DD MMM YYYY'),
          endDate: endDate.format('DD MMM YYYY'),
          status: getStatus(startDate, endDate),
          calculated_dates: getDaysDiff(startDate, endDate),
          __status: REPEATER_ITEM_STATUS.GENERATED,
        })
      },
      onRemove: (index) => {
        const item = data.value[index]
        if (item) remove(item, index)
      },
    },
  )

  watch(
    [
      () => settings.value?.outgoingYearType,
      leaseStartDate,
      leaseEndDate,
      financialYearDate,
    ],
    ([outgoingYearType]) => {
      if (outgoingYearType)
        generate(outgoingYearTypeToYearType(outgoingYearType))
    },
    { immediate: true },
  )

  return { data: filteredData }
}

function getStatus(startDate: Dayjs, endDate: Dayjs) {
  const today = dayjs()
  let status: YearModel['status'] = 'future'

  if (today.isAfter(endDate)) {
    status = 'past'
  } else if (
    (today.isSame(startDate) || today.isAfter(startDate)) &&
    (today.isSame(endDate) || today.isBefore(endDate))
  ) {
    status = 'present'
  }

  return status
}

function getDaysDiff(startDate: Dayjs, endDate: Dayjs) {
  return endDate.diff(startDate, 'day') + 1
}

function outgoingYearTypeToYearType(yearType: OutgoingYearType) {
  switch (yearType) {
    case OutgoingYearType.CALENDAR_YEAR:
      return YearType.CALENDAR_YEAR
    case OutgoingYearType.LEASE_YEAR:
      return YearType.LEASE_YEAR
    case OutgoingYearType.FINANCIAL_YEAR_END:
      return YearType.FINANCIAL_YEAR_END
    case OutgoingYearType.OTHER_YEAR_END:
      return YearType.OTHER_YEAR_END
  }
}
