<script setup lang="ts">
import type { Ref, WritableComputedRef } from 'vue'
import { createColumnHelper } from '@tanstack/vue-table'
import {
  type TanstackTableColumn,
  useTanstackTableVariants,
} from '@ui/components/TanstackTable'
import type { FormKitNode } from '@formkit/core'
import {
  type IManagerFormNodeValue,
  type INodeField,
  type INodeObject,
  joinPath,
  parseSizeToGrid,
  useFormula,
  useObjectVModel,
} from '@manager'
import type {
  IManagerTableEmits,
  IManagerTableProps,
} from '@manager/components/Group/Table/types'
import {
  generateOutgoingsTableData,
  type YearModel,
  type OutgoingWithYear,
} from './composables/yearGenerators'
import type { Outgoing, OutgoingForm } from './types'

type OutgoingWithYears = Outgoing & {
  startDate: YearModel['startDate']
  endDate: YearModel['endDate']
  status: YearModel['status']
}

const props = withDefaults(defineProps<IManagerTableProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})

const dayjs = useDayjs()
const emit = defineEmits<IManagerTableEmits>()

const colSize = computed(() => parseSizeToGrid(props.node.size))

const objectNode = props.node.nodes[0] as INodeObject
const objectPath = joinPath(props.parentPath, objectNode.name)
const joinDataPath = (index: number) => joinPath(objectPath, `data[${index}]`)

const [_, dataModelValue] = useObjectVModel(
  {
    ...props,
    node: objectNode,
  },
  'parentValue',
  emit,
) as unknown as readonly [
  Ref<IManagerFormNodeValue>,
  WritableComputedRef<OutgoingWithYears[]>,
]

const { formValue } = useManagerFormValue<OutgoingForm>()
const settings = computed(() => formValue?.value?.outgoingsSettings?.data?.[0])
const increase = computed(() => formValue?.value?.outgoingsIncrease?.data?.[0])

const annualIncrease = computed(() => increase.value.setAnnualIncrease)

const { data } = generateOutgoingsTableData({
  initialValue: dataModelValue,
  objectPath,
  nodes: objectNode.nodes,
})

const cache = new WeakMap<
  INodeField,
  { castPlugin: (node: FormKitNode) => void }
>()
objectNode.nodes.forEach((node) => {
  const { castPlugin } = useFieldCast(node as INodeField)
  cache.set(node as INodeField, { castPlugin })
})

const columnHelper = createColumnHelper<OutgoingWithYears>()
const columns = computed(() => {
  const columns = objectNode.nodes.map((node) => {
    return columnHelper.accessor(node.name as keyof Outgoing, {
      header: node.label ?? undefined,
      meta: {
        slotProps: { node },
      },
    })
  })

  return [...columns]
})

const variant = useTanstackTableVariants({
  variants: {
    status: {
      present: {
        tbodyTr: '!bg-white/5',
      },
    },
  },
})

const ownershipTooltip = (value: number, prefix: string) => {
  if (value > 0) {
    return `You owe the lessor ${prefix}${value}`
  }
  if (value < 0) {
    return `Lessor owns you ${prefix}${String(value).replace('-', '')}`
  }
  if (value === 0) {
    return `You and lessor are even`
  }
}

const shouldDisplayVariance = ({
  data,
  index,
}: {
  data: ITableDataItem<OutgoingWithYear>
  index: number
}) => {
  return !isNullish(data[index]['actual']) && !isNullish(data[index]['budget'])
}
</script>

<template>
  <div :class="[colSize, 'grid grid-flow-row grid-cols-1 gap-2']">
    <template v-if="!settings.outgoingYearType">
      <div v-if="node.label" class="text-base text-white">
        {{ node.label }}
      </div>

      <div
        class="bg-warning/5 border-warning/70 text-warning w-fit rounded-lg border-2 px-8 py-4"
      >
        Fill all information to check the outgoings table.
      </div>
    </template>

    <div v-else>
      <div
        class="mb-2 flex w-full items-center"
        :class="[node.label ? 'justify-between' : 'justify-end']"
      >
        <span v-if="node.label" class="text-base text-white">
          {{ node.label }}
        </span>
        <span class="px-2 text-xs">
          <time>
            {{ dayjs(settings.leaseStartDate).format('DD MMM YYYY') }}
          </time>
          -
          <time>
            {{ dayjs(settings.leaseEndDate).format('DD MMM YYYY') }}
          </time>
        </span>
      </div>

      <!-- TODO: Tanstack table cache is not updating, so we need to display the items (hidden) to force the cache to update -->
      <div class="hidden">
        {{ data }}
      </div>

      <TanstackTable
        class="outgoings-table border border-gray-800"
        :columns="columns"
        :data="[...data]"
        :sticky="{ scroller: null }"
        rounded
        :variant="variant"
        :class-tbody-tr="(row) => ({ status: row.original.status })"
      >
        <!-- Empty -->
        <template #empty>
          <div
            class="flex items-center justify-center px-4 py-3 text-sm text-gray-500"
          >
            <span>
              {{ node.emptyMessage ?? "You haven't added items yet." }}
            </span>
          </div>
        </template>

        <!-- Year -->
        <template #item-year="{ item }: TanstackTableColumn<OutgoingWithYears>">
          <span
            class="text font-lg flex w-full justify-center font-bold"
            :class="{
              'text-success': item.status === 'present',
              'text-success/30': item.status === 'past',
              'text-gray-400': item.status === 'future',
            }"
          >
            {{ item.year }}
          </span>
        </template>

        <!-- Dates -->
        <template
          #item-dates="{ item }: TanstackTableColumn<OutgoingWithYears>"
        >
          <Tooltip
            :content="`${item.calculated_dates} days`"
            class="bg-gray-900 text-xs"
            placement="top"
          >
            <div class="flex min-w-max items-center gap-2 whitespace-nowrap">
              <time class="text-gray-100">
                {{ item.startDate }}
              </time>
              <span class="text-gray-600"> - </span>
              <time class="text-gray-100">
                {{ item.endDate }}
              </time>
            </div>
          </Tooltip>
        </template>

        <!-- Budget -->
        <template
          #item-budget="{
            id,
            index,
            value,
            node,
          }: TanstackTableColumn<OutgoingWithYears>"
        >
          <!-- Budget (only for annual increase) -->
          <template v-if="annualIncrease || index === 0">
            <div class="w-52">
              <CurrencyDisplay
                :value="value"
                :prefix="node.prefix"
                :suffix="node.suffix"
              />
            </div>
          </template>
          <!-- Budget (for the rest) -->
          <template v-else>
            <div class="flex h-full w-full">
              <FormKit
                v-if="data[index]"
                v-model="data[index][id]"
                outer-class="w-52"
                inner-class=" group-hover/tr:border-primary/70 group-hover/tr:ring-primary/20 group-hover/tr:border group-hover/tr:ring-4 group-hover/tr:[&>span:first-child]:text-gray-100"
                type="currency"
                currency="AUD"
                display-locale="en-AU"
                :plugins="cache.has(node) ? [cache.get(node)!.castPlugin] : []"
                min="0"
                max="10000000"
                decimals="2"
                validation="number|min:0|max:10000000"
                :name="id"
              >
                <template v-if="node.suffix" #suffix>
                  <span
                    class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
                  >
                    {{ node.suffix }}
                  </span>
                </template>
              </FormKit>
            </div>
          </template>
        </template>

        <!-- Actual -->
        <template
          #item-actual="{
            id,
            index,
            node,
          }: TanstackTableColumn<OutgoingWithYears>"
        >
          <div class="flex h-full w-full">
            <FormKit
              v-if="data[index]"
              v-model="data[index][id]"
              :name="id"
              type="currency"
              currency="AUD"
              display-locale="en-AU"
              :plugins="cache.has(node) ? [cache.get(node)!.castPlugin] : []"
              min="0"
              max="10000000"
              decimals="2"
              validation="number|min:0|max:10000000"
              outer-class="max-w-52"
              inner-class=" group-hover/tr:border-primary/70 group-hover/tr:ring-primary/20 group-hover/tr:border group-hover/tr:ring-4 group-hover/tr:[&>span:first-child]:text-gray-100"
            >
              <template v-if="node.suffix" #suffix>
                <span
                  class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
                >
                  {{ node.suffix }}
                </span>
              </template>
            </FormKit>
          </div>
        </template>

        <!-- Variance -->
        <template
          #item-variance="{
            node,
            value,
            index,
          }: TanstackTableColumn<OutgoingWithYears>"
        >
          <CurrencyDisplay
            :value="shouldDisplayVariance({ data, index }) ? value : null"
            :prefix="node.prefix"
            :tooltip="
              shouldDisplayVariance({ data, index })
                ? ownershipTooltip(value, node.prefix)
                : 'Fill budget to check the value'
            "
            show-sign
            invert-sign-color
          />
        </template>

        <!-- Payable Date -->
        <template
          #item-payableDate="{
            id,
            index,
            node,
          }: TanstackTableColumn<OutgoingWithYears>"
        >
          <FormKit
            v-if="data[index]"
            v-model="data[index][id]"
            inner-class=" group-hover/tr:border-primary/70 group-hover/tr:ring-primary/20 group-hover/tr:border group-hover/tr:ring-4 group-hover/tr:[&>span:first-child]:text-gray-100"
            type="datepicker"
            :name="id"
            placeholder="Date"
            :plugins="cache.has(node) ? [cache.get(node)!.castPlugin] : []"
            outer-class="w-32"
          >
            <template v-if="node.prefix" #prefix>
              <span
                class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                {{ node.prefix }}
              </span>
            </template>
            <template v-if="node.suffix" #suffix>
              <span
                class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
              >
                {{ node.suffix }}
              </span>
            </template>
          </FormKit>
        </template>
      </TanstackTable>
    </div>
  </div>
</template>
